// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-yourbox-js": () => import("./../../../src/pages/yourbox.js" /* webpackChunkName: "component---src-pages-yourbox-js" */),
  "component---src-templates-learn-template-js": () => import("./../../../src/templates/learn-template.js" /* webpackChunkName: "component---src-templates-learn-template-js" */),
  "component---src-templates-premade-box-template-js": () => import("./../../../src/templates/premadeBox-template.js" /* webpackChunkName: "component---src-templates-premade-box-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

