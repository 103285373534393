module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"MmQxZTc1NWUtODgzYy00MTFjLTg3MTktOThlMjY3YjVkYjBkNjM3MzkwODU3NzcwOTQ5Mjk4","openCartOnAdd":false,"useSideCart":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"speed":800},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5235b0ec47942feefbd864d3bd9d6b28"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
